@import 'styles/breakpoints';
@import 'styles/layout';
@import 'styles/fonts';

html {
    height: 100vh;
    box-sizing: border-box;
    font-family: $GTWalsheim;
    font-size: 62.5%;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    -webkit-tap-highlight-color: transparent;
    touch-action: manipulation;
}

*,
*::before,
*::after {
    box-sizing: inherit;
}

body {
    background-color: var(--colors-cream);
    color: var(--colors-black);
    font-size: 1.6rem;

    &.theme-makers-reserve {
        background-color: #191815;
        color: var(--colors-cream);
    }
}

/* stylelint-disable */
html,
body,
#__next {
    max-width: 100%;
}
/* stylelint-enable */

h1,
h2,
h3,
h4,
h5,
h6 {
    padding: 0;
    margin: 0;
    color: inherit;
    font-family: $GTWalsheimCond;
    font-weight: bold;
    /* stylelint-disable */
    text-wrap: balance;
    /* stylelint-enable */
}

a {
    color: inherit;
}

p,
ul {
    margin: 0 0 1.5em;
    /* stylelint-disable */
    text-wrap: pretty;
    /* stylelint-enable */
}

figure {
    margin: 0;
}

img {
    display: block;
    max-width: 100%;
    height: auto;
}

button {
    cursor: pointer;
}

.sr-only {
    position: absolute;
    overflow: hidden;
    width: 1px;
    height: 1px;
    padding: 0;
    border: 0;
    margin: -1px;
    clip: rect(0 0 0 0);
}

#nprogress {
    position: relative;
    z-index: var(--z-loader);
}

.svg-icon {
    color: inherit;
    font-size: 1rem;
    line-height: 1;
}

.svg-icon > svg {
    width: 100%;
    height: 100%;
}

/* stylelint-disable */
.xola-pii-consent-banner {
    background: var(--colors-cream) !important;
    box-shadow: 0 0 20px rgb(108 105 86 / 75%) !important;
}

.xola-pii-consent-message {
    max-width: 89rem !important;
    padding-right: 2rem;
    color: var(--colors-blue) !important;
    font-family: $GTWalsheim !important;
    font-size: 1.4rem !important;
    line-height: 1.6rem !important;
}

.xola-pii-consent-action-close {
    top: 0 !important;
    bottom: auto !important;
    padding: 1.4rem 2rem !important;
    color: var(--colors-blue) !important;
    font-size: 25px !important;
    line-height: 1rem !important;
}

.xola-pii-consent-action-accept {
    height: 5rem !important;
    padding: 0 3rem !important;
    padding-top: 1px !important;
    background-color: var(--colors-blue) !important;
    border-radius: 1rem !important;
    color: var(--colors-cream) !important;
    font-family: $GTWalsheimUltraBold !important;
    font-size: 2rem !important;
    font-weight: 800 !important;

    text-transform: uppercase;
}
/* stylelint-enable */

@include medium {
    .xola-pii-consent-banner {
        display: flex !important;
        align-items: center !important;
        justify-content: center !important;
    }
}

:root {
    --breakpoints-medium: #{$medium};
    --breakpoints-large: #{$large};
    --breakpoints-xLarge: #{$xLarge};
    --breakpoints-xxLarge: #{$xxLarge};
    --breakpoints-xxxLarge: #{$xxxLarge};
}

@include print {
    html {
        font-size: 42.5%;
    }

    body {
        background-color: white;
    }
}
