@import 'styles/breakpoints';

// Layout Nomenclature:
// End: is Top and Bottom, aka Vertical
// Side: is Left and Right, aka Horizontal
// -------------------------

$blockPaddingEnd: 4.8rem;
$blockPaddingSide: 1rem;

$blockPaddingEndMedium: 4.8rem;
$blockPaddingSideMedium: 4.8rem;

$blockPaddingEndLarge: 4.8rem;
$blockPaddingSideLarge: 6rem;

$blockPaddingEndXLarge: 6rem;
$blockPaddingSideXLarge: 9.6rem;

$blockPaddingEndXXLarge: 9.6rem;
$blockPaddingSideXXLarge: 9.6rem;

// extra space before footer for divot
$lastBlockPaddingBottom: 2rem;

// Grid
$gridPaddingSide: 2.4rem;
$gridPaddingSideMedium: 4.8rem;
$gridPaddingSideLarge: 6rem;
$gridPaddingSideXLarge: 9.6rem;
$gridPaddingSideXXLarge: 9.6rem;

$gridSideOffset: -1.2rem;

:root {
    --news-richText-max-width: 64.9rem;

    --max-width: 192rem;
    --block-max-width: 142.08rem;
    --block-max-width-plus-padding: 161.28rem;
    --block-max-height: 108rem;

    --z-godmode: 9000;
    --z-loader: 8000;
    --z-modal: 7000;
    --z-header: 6000;
    --z-nav: 5000;
    --z-subnav: 4000;
    --z-storeSuggestions: 10000;
    --z-searchbar: 11000;

    --block-padding-end: #{$blockPaddingEnd};
    --block-padding-side: #{$blockPaddingSide};
    --block-padding: #{$blockPaddingEnd} #{$blockPaddingSide};
    --last-block-padding-bottom: #{$blockPaddingEnd + $lastBlockPaddingBottom};

    --grid-padding-side: #{$gridPaddingSide};
    --grid-margin-side-offset: #{$gridSideOffset};
}

@include medium {
    :root {
        --block-padding-end: #{$blockPaddingEndMedium};
        --block-padding-side: #{$blockPaddingSideMedium};
        --block-padding: #{$blockPaddingEndMedium} #{$blockPaddingSideMedium};
        --last-block-padding-bottom: #{$blockPaddingEndMedium +
            $lastBlockPaddingBottom};

        --grid-padding-side: #{$gridPaddingSideMedium};
        --grid-margin-side-offset: 0;
    }
}
@include large {
    :root {
        --block-padding-end: #{$blockPaddingEndLarge};
        --block-padding-side: #{$blockPaddingSideLarge};
        --block-padding: #{$blockPaddingEndLarge} #{$blockPaddingSideLarge};
        --last-block-padding-bottom: #{$blockPaddingEndLarge +
            $lastBlockPaddingBottom};

        --grid-padding-side: #{$gridPaddingSideLarge};
    }
}
@include xLarge {
    :root {
        --block-padding-end: #{$blockPaddingEndXLarge};
        --block-padding-side: #{$blockPaddingSideXLarge};
        --block-padding: #{$blockPaddingEndXLarge} #{$blockPaddingSideXLarge};
        --last-block-padding-bottom: #{$blockPaddingEndXLarge +
            $lastBlockPaddingBottom};

        --grid-padding-side: #{$gridPaddingSideXLarge};
    }
}
@include xxLarge {
    :root {
        --block-padding-end: #{$blockPaddingEndXXLarge};
        --block-padding-side: #{$blockPaddingSideXXLarge};
        --block-padding: #{$blockPaddingEndXXLarge} #{$blockPaddingSideXXLarge};
        --last-block-padding-bottom: #{$blockPaddingEndXXLarge +
            $lastBlockPaddingBottom};

        --grid-padding-side: #{$gridPaddingSideXXLarge};
    }
}
