@import 'https://use.typekit.net/lta2tkw.css';

@font-face {
    font-display: swap;
    font-family: 'GT Walsheim Pro';
    font-style: normal;
    font-weight: 300;
    src: local('GT Walsheim Pro Light'), local('GTWalsheimPro-Light'),
        url('https://static.tillamook.com/fonts/GTWalsheimPro-Light.woff2')
            format('woff2'),
        url('https://static.tillamook.com/fonts/GTWalsheimPro-Light.woff')
            format('woff');
}

@font-face {
    font-display: swap;
    font-family: 'GT Walsheim Pro';
    font-style: normal;
    font-weight: normal;
    src: local('GT Walsheim Pro Regular'), local('GTWalsheimPro-Regular'),
        url('https://static.tillamook.com/fonts/GTWalsheimPro-Regular.woff2')
            format('woff2'),
        url('https://static.tillamook.com/fonts/GTWalsheimPro-Regular.woff')
            format('woff');
}

@font-face {
    font-family: 'GT Walsheim Pro';
    font-style: italic;
    font-weight: 100;
    src: local('GT Walsheim Pro Thin Oblique'),
        local('GTWalsheimPro-ThinOblique'),
        url('https://static.tillamook.com/fonts/GTWalsheimPro-ThinOblique.woff2')
            format('woff2'),
        url('https://static.tillamook.com/fonts/GTWalsheimPro-ThinOblique.woff')
            format('woff');
}

@font-face {
    font-display: swap;
    font-family: 'GT Walsheim Pro';
    font-style: normal;
    font-weight: 100;
    src: local('GT Walsheim Pro Thin'), local('GTWalsheimPro-Thin'),
        url('https://static.tillamook.com/fonts/GTWalsheimPro-Thin.woff2')
            format('woff2'),
        url('https://static.tillamook.com/fonts/GTWalsheimPro-Thin.woff')
            format('woff');
}

@font-face {
    font-family: 'GT Walsheim Pro';
    font-style: italic;
    font-weight: bold;
    src: local('GT Walsheim Pro Bold Oblique'),
        local('GTWalsheimPro-BoldOblique'),
        url('https://static.tillamook.com/fonts/GTWalsheimPro-BoldOblique.woff2')
            format('woff2'),
        url('https://static.tillamook.com/fonts/GTWalsheimPro-BoldOblique.woff')
            format('woff');
}

@font-face {
    font-display: swap;
    font-family: 'GT Walsheim Pro Ultra';
    font-style: normal;
    font-weight: 800;
    src: local('GT Walsheim Pro Ultra Bold'), local('GTWalsheimPro-UltraBold'),
        url('https://static.tillamook.com/fonts/GTWalsheimPro-UltraBold-Custom.woff2')
            format('woff2'),
        url('https://static.tillamook.com/fonts/GTWalsheimPro-UltraBold-Custom.woff')
            format('woff');
}

@font-face {
    font-family: 'GT Walsheim Pro Ultra';
    font-style: italic;
    font-weight: 800;
    src: local('GT Walsheim Pro Ultra Bold Oblique'),
        local('GTWalsheimPro-UltraBoldOblique'),
        url('https://static.tillamook.com/fonts/GTWalsheimPro-UltraBoldOblique.woff2')
            format('woff2'),
        url('https://static.tillamook.com/fonts/GTWalsheimPro-UltraBoldOblique.woff')
            format('woff');
}

@font-face {
    font-family: 'GT Walsheim Pro';
    font-style: italic;
    font-weight: 500;
    src: local('GT Walsheim Pro Medium Oblique'),
        local('GTWalsheimPro-MediumOblique'),
        url('https://static.tillamook.com/fonts/GTWalsheimPro-MediumOblique.woff2')
            format('woff2'),
        url('https://static.tillamook.com/fonts/GTWalsheimPro-MediumOblique.woff')
            format('woff');
}

@font-face {
    font-display: swap;
    font-family: 'GT Walsheim Pro';
    font-style: normal;
    font-weight: bold;
    src: local('GT Walsheim Pro Bold'), local('GTWalsheimPro-Bold'),
        url('https://static.tillamook.com/fonts/GTWalsheimPro-Bold.woff2')
            format('woff2'),
        url('https://static.tillamook.com/fonts/GTWalsheimPro-Bold.woff')
            format('woff');
}

@font-face {
    font-display: swap;
    font-family: 'GT Walsheim Pro';
    font-style: normal;
    font-weight: 500;
    src: local('GT Walsheim Pro Medium'), local('GTWalsheimPro-Medium'),
        url('https://static.tillamook.com/fonts/GTWalsheimPro-Medium.woff2')
            format('woff2'),
        url('https://static.tillamook.com/fonts/GTWalsheimPro-Medium.woff')
            format('woff');
}

@font-face {
    font-family: 'GT Walsheim Pro';
    font-style: italic;
    font-weight: normal;
    src: local('GT Walsheim Pro Regular Oblique'),
        local('GTWalsheimPro-RegularOblique'),
        url('https://static.tillamook.com/fonts/GTWalsheimPro-RegularOblique.woff2')
            format('woff2'),
        url('https://static.tillamook.com/fonts/GTWalsheimPro-RegularOblique.woff')
            format('woff');
}

@font-face {
    font-family: 'GT Walsheim Pro';
    font-style: italic;
    font-weight: 300;
    src: local('GT Walsheim Pro Light Oblique'),
        local('GTWalsheimPro-LightOblique'),
        url('https://static.tillamook.com/fonts/GTWalsheimPro-LightOblique.woff2')
            format('woff2'),
        url('https://static.tillamook.com/fonts/GTWalsheimPro-LightOblique.woff')
            format('woff');
}

@font-face {
    font-display: swap;
    font-family: 'GT Walsheim Pro Ultra';
    font-style: normal;
    font-weight: 200;
    src: local('GT Walsheim Pro Ultra Light'), local('GTWalsheimPro-UltraLight'),
        url('https://static.tillamook.com/fonts/GTWalsheimPro-UltraLight.woff2')
            format('woff2'),
        url('https://static.tillamook.com/fonts/GTWalsheimPro-UltraLight.woff')
            format('woff');
}

@font-face {
    font-family: 'GT Walsheim Pro Ultra';
    font-style: italic;
    font-weight: 200;
    src: local('GT Walsheim Pro Ultra Light Oblique'),
        local('GTWalsheimPro-UltraLightOblique'),
        url('https://static.tillamook.com/fonts/GTWalsheimPro-UltraLightOblique.woff2')
            format('woff2'),
        url('https://static.tillamook.com/fonts/GTWalsheimPro-UltraLightOblique.woff')
            format('woff');
}

@font-face {
    font-family: 'GT Walsheim Pro';
    font-style: italic;
    font-weight: 900;
    src: local('GT Walsheim Pro Black Oblique'),
        local('GTWalsheimPro-BlackOblique'),
        url('https://static.tillamook.com/fonts/GTWalsheimPro-BlackOblique.woff2')
            format('woff2'),
        url('https://static.tillamook.com/fonts/GTWalsheimPro-BlackOblique.woff')
            format('woff');
}

@font-face {
    font-display: swap;
    font-family: 'GT Walsheim Pro';
    font-style: normal;
    font-weight: 900;
    src: local('GT Walsheim Pro Black'), local('GTWalsheimPro-Black'),
        url('https://static.tillamook.com/fonts/GTWalsheimPro-Black.woff2')
            format('woff2'),
        url('https://static.tillamook.com/fonts/GTWalsheimPro-Black.woff')
            format('woff');
}

@font-face {
    font-display: swap;
    font-family: 'GT Walsheim Pro Cond Ultra';
    font-style: normal;
    font-weight: 800;
    src: local('GT Walsheim Pro Condensed Ultra Bold'),
        local('GTWalsheimPro-CondensedUltraBold'),
        url('https://static.tillamook.com/fonts/GTWalsheimPro-CondensedUltraBold.woff2')
            format('woff2'),
        url('https://static.tillamook.com/fonts/GTWalsheimPro-CondensedUltraBold.woff')
            format('woff');
}

@font-face {
    font-display: swap;
    font-family: 'GT Walsheim Pro Condensed';
    font-style: normal;
    font-weight: 300;
    src: local('GT Walsheim Pro Condensed Light'),
        local('GTWalsheimPro-CondensedLight'),
        url('https://static.tillamook.com/fonts/GTWalsheimPro-CondensedLight.woff2')
            format('woff2'),
        url('https://static.tillamook.com/fonts/GTWalsheimPro-CondensedLight.woff')
            format('woff');
}

@font-face {
    font-family: 'GT Walsheim Pro Condensed';
    font-style: italic;
    font-weight: bold;
    src: local('GT Walsheim Pro Condensed Bold Oblique'),
        local('GTWalsheimPro-CondensedBoldOblique'),
        url('https://static.tillamook.com/fonts/GTWalsheimPro-CondensedBoldOblique.woff2')
            format('woff2'),
        url('https://static.tillamook.com/fonts/GTWalsheimPro-CondensedBoldOblique.woff')
            format('woff');
}

@font-face {
    font-family: 'GT Walsheim Pro Condensed';
    font-style: italic;
    font-weight: 300;
    src: local('GT Walsheim Pro Condensed Light Oblique'),
        local('GTWalsheimPro-CondensedLightOblique'),
        url('https://static.tillamook.com/fonts/GTWalsheimPro-CondensedLightOblique.woff2')
            format('woff2'),
        url('https://static.tillamook.com/fonts/GTWalsheimPro-CondensedLightOblique.woff')
            format('woff');
}

@font-face {
    font-display: swap;
    font-family: 'GT Walsheim Pro Condensed';
    font-style: normal;
    font-weight: 100;
    src: local('GT Walsheim Pro Condensed Thin'),
        local('GTWalsheimPro-CondensedThin'),
        url('https://static.tillamook.com/fonts/GTWalsheimPro-CondensedThin.woff2')
            format('woff2'),
        url('https://static.tillamook.com/fonts/GTWalsheimPro-CondensedThin.woff')
            format('woff');
}

@font-face {
    font-family: 'GTWalsheimPro-CondensedULightIt';
    font-style: italic;
    font-weight: 200;
    src: local('GT Walsheim Pro Condensed Ultra Light Oblique'),
        local('GTWalsheimPro-CondensedUltraLightOblique'),
        url('https://static.tillamook.com/fonts/GTWalsheimPro-CondensedUltraLightOblique.woff2')
            format('woff2'),
        url('https://static.tillamook.com/fonts/GTWalsheimPro-CondensedUltraLightOblique.woff')
            format('woff');
}

@font-face {
    font-display: swap;
    font-family: 'GT Walsheim Pro Cond';
    font-style: normal;
    font-weight: 500;
    src: local('GT Walsheim Pro Condensed Medium'),
        local('GTWalsheimPro-CondensedMedium'),
        url('https://static.tillamook.com/fonts/GTWalsheimPro-CondensedMedium.woff2')
            format('woff2'),
        url('https://static.tillamook.com/fonts/GTWalsheimPro-CondensedMedium.woff')
            format('woff');
}

@font-face {
    font-family: 'GT Walsheim Pro Condensed';
    font-style: italic;
    font-weight: 900;
    src: local('GT Walsheim Pro Condensed Black Oblique'),
        local('GTWalsheimPro-CondensedBlackOblique'),
        url('https://static.tillamook.com/fonts/GTWalsheimPro-CondensedBlackOblique.woff2')
            format('woff2'),
        url('https://static.tillamook.com/fonts/GTWalsheimPro-CondensedBlackOblique.woff')
            format('woff');
}

@font-face {
    font-display: swap;
    font-family: 'GT Walsheim Pro Condensed';
    font-style: normal;
    font-weight: normal;
    src: local('GT Walsheim Pro Condensed Regular'),
        local('GTWalsheimPro-CondensedRegular'),
        url('https://static.tillamook.com/fonts/GTWalsheimPro-CondensedRegular.woff2')
            format('woff2'),
        url('https://static.tillamook.com/fonts/GTWalsheimPro-CondensedRegular.woff')
            format('woff');
}

@font-face {
    font-family: 'GT Walsheim Pro Condensed';
    font-style: italic;
    font-weight: 100;
    src: local('GT Walsheim Pro Condensed Thin Oblique'),
        local('GTWalsheimPro-CondensedThinOblique'),
        url('https://static.tillamook.com/fonts/GTWalsheimPro-CondensedThinOblique.woff2')
            format('woff2'),
        url('https://static.tillamook.com/fonts/GTWalsheimPro-CondensedThinOblique.woff')
            format('woff');
}

@font-face {
    font-display: swap;
    font-family: 'GT Walsheim Pro Condensed';
    font-style: normal;
    font-weight: bold;
    src: local('GT Walsheim Pro Condensed Bold'),
        local('GTWalsheimPro-CondensedBold'),
        url('https://static.tillamook.com/fonts/GTWalsheimPro-CondensedBold.woff2')
            format('woff2'),
        url('https://static.tillamook.com/fonts/GTWalsheimPro-CondensedBold.woff')
            format('woff');
}

@font-face {
    font-family: 'GT Walsheim Pro Condensed';
    font-style: italic;
    font-weight: normal;
    src: local('GT Walsheim Pro Condensed Regular Oblique'),
        local('GTWalsheimPro-CondensedRegularOblique'),
        url('https://static.tillamook.com/fonts/GTWalsheimPro-CondensedRegularOblique.woff2')
            format('woff2'),
        url('https://static.tillamook.com/fonts/GTWalsheimPro-CondensedRegularOblique.woff')
            format('woff');
}

@font-face {
    font-display: swap;
    font-family: 'GTWalsheimPro-CondensedULight';
    font-style: normal;
    font-weight: 200;
    src: local('GT Walsheim Pro Condensed Ultra Light'),
        local('GTWalsheimPro-CondensedUltraLight'),
        url('https://static.tillamook.com/fonts/GTWalsheimPro-CondensedUltraLight.woff2')
            format('woff2'),
        url('https://static.tillamook.com/fonts/GTWalsheimPro-CondensedUltraLight.woff')
            format('woff');
}

@font-face {
    font-family: 'GT Walsheim Pro Cond Ultra';
    font-style: italic;
    font-weight: 800;
    src: local('GT Walsheim Pro Condensed Ultra Bold Oblique'),
        local('GTWalsheimPro-CondensedUltraBoldOblique'),
        url('https://static.tillamook.com/fonts/GTWalsheimPro-CondensedUltraBoldOblique.woff2')
            format('woff2'),
        url('https://static.tillamook.com/fonts/GTWalsheimPro-CondensedUltraBoldOblique.woff')
            format('woff');
}

@font-face {
    font-family: 'GT Walsheim Pro Cond';
    font-style: italic;
    font-weight: 500;
    src: local('GT Walsheim Pro Condensed Medium Oblique'),
        local('GTWalsheimPro-CondensedMediumOblique'),
        url('https://static.tillamook.com/fonts/GTWalsheimPro-CondensedMediumOblique.woff2')
            format('woff2'),
        url('https://static.tillamook.com/fonts/GTWalsheimPro-CondensedMediumOblique.woff')
            format('woff');
}

@font-face {
    font-display: swap;
    font-family: 'GT Walsheim Pro Condensed';
    font-style: normal;
    font-weight: 900;
    src: local('GT Walsheim Pro Condensed Black'),
        local('GTWalsheimPro-CondensedBlack'),
        url('https://static.tillamook.com/fonts/GTWalsheimPro-CondensedBlack.woff2')
            format('woff2'),
        url('https://static.tillamook.com/fonts/GTWalsheimPro-CondensedBlack.woff')
            format('woff');
}

@font-face {
    font-display: swap;
    font-family: 'parkinson';
    font-style: normal;
    font-weight: 500;
    src: local('parkinson'),
        url('https://static.tillamook.com/fonts/Parkinson-Medium.woff2')
            format('woff2'),
        url('https://static.tillamook.com/fonts/Parkinson-Medium.woff')
            format('woff');
}

@font-face {
    font-display: swap;
    font-family: 'Register';
    font-style: normal;
    src: local('Register'),
        url('https://static.tillamook.com/fonts/register.woff2') format('woff2'),
        url('https://static.tillamook.com/fonts/register.woff') format('woff');
}
