@use '~@tillamook/brand/lib/colors' as brand-colors;
@import 'colors';
@import 'breakpoints';

:root {
    // load in brand colors
    @each $key, $value in brand-colors.$colors {
        --colors-#{unquote($key)}: #{$value};
    }

    // load in site colors
    @each $key, $value in $colors {
        --colors-#{unquote($key)}: #{$value};
    }

    --header-height: 6rem;
    --header-subnav-height: 6rem;
    --header-logo-width: 100;
    --landing-header-logo-visibility: hidden;
    --landing-header-background: transparent;
    --landing-header-transform-visible: translateY(-100%);
    --landing-header-transform-hidden: translateY(0);
}

@include large {
    :root {
        --header-height: 8rem;
        --header-logo-width: 124;
    }
}
